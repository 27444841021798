import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { ConfigProps } from "@intility/flex-chat";
import { createGenerateClassName, StylesProvider, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authenticator from "./components/Authenticator";
import { LoadingComponent } from "./components/Loading";
import { graphScope, msalInstance } from "./config/auth/config";
import theme from "./config/theme/theme";
import CompanyPage from "./pages/CompanyPage";
import PortalTestPage from "./pages/PortalTestPage";
import TranslationPage from "./pages/TranslationPage";
import WebShopPage from "./pages/WebShopPage";
import { useStore } from "./state/store";

const generateClassName = createGenerateClassName({
    productionPrefix: "c",
});

const App: React.FC<unknown> = () => {
    const { REACT_APP_ACCOUNT_SID, REACT_APP_FLOW_SID, REACT_APP_ENV } = process.env;
    const { state } = useStore();

    const [chatConfig, setChatConfig] = useState<ConfigProps | undefined>(undefined);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const problemDescription = urlParams.get("description");
    const isDev = REACT_APP_ENV === "qa";

    useEffect(() => {
        if (state?.userInfo?.userPrincipalName && REACT_APP_ACCOUNT_SID && REACT_APP_FLOW_SID) {
            setChatConfig({
                flexAccountSid: REACT_APP_ACCOUNT_SID,
                flexFlowSid: REACT_APP_FLOW_SID,
                closeOnInit: false,
                user: {
                    userPrincipalName: state?.userInfo?.userPrincipalName,
                    friendlyName: state?.userInfo?.displayName,
                    mail: state?.userInfo?.mail,
                    mobilePhone: state?.userInfo?.mobilePhone,
                    preferredLanguage: state?.userInfo?.preferredLanguage,
                },
            });
        }
        // eslint-disable-next-line
    }, [state?.userInfo, REACT_APP_ACCOUNT_SID, REACT_APP_FLOW_SID]);

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider generateClassName={generateClassName}>
                <MsalProvider instance={msalInstance}>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={graphScope}
                        loadingComponent={LoadingComponent}
                    >
                        <Authenticator>
                            <BrowserRouter>
                                <Routes>
                                    <Route path='/portalTest' element={<PortalTestPage />} />
                                    <Route
                                        path='/dev'
                                        element={
                                            <CompanyPage chatConfig={chatConfig} isDev={true} problemDescription={problemDescription} />
                                        }
                                    />
                                    <Route
                                        path='/translate'
                                        element={
                                            <TranslationPage
                                                chatConfig={chatConfig}
                                                isDev={isDev}
                                                problemDescription={problemDescription}
                                            />
                                        }
                                    />
                                    <Route path='/webshop' element={<WebShopPage chatConfig={chatConfig} isDev={isDev} />} />
                                    <Route
                                        path='*'
                                        element={
                                            <CompanyPage chatConfig={chatConfig} isDev={isDev} problemDescription={problemDescription} />
                                        }
                                    />
                                </Routes>
                            </BrowserRouter>
                        </Authenticator>
                    </MsalAuthenticationTemplate>
                </MsalProvider>
            </StylesProvider>
        </ThemeProvider>
    );
};

export default App;
