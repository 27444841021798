import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { StoreProvider } from './state/store';
import Header from './components/Header';
import NotSupported from './components/NotSupported';

const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || !!document['documentMode'];

const Wrapper = () => {
    return (
        <main style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {isIE ? (
                <>
                    <Header isAuthenticated={false}/>
                    <NotSupported />
                </>
            ) : (
                    <StoreProvider>
                        <App />
                    </StoreProvider>
                )}
        </main>
    );
};

ReactDOM.render(<Wrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
