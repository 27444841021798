import { createTheme, hslToRgb, rgbToHex } from '@material-ui/core/styles';
import { green, red, amber, brown, lightGreen } from '@material-ui/core/colors';

/* export const bifrostColors = {
    darkBackground: hslToRgb('hsl(0,0,10)'),
    darkGreyBackground: hslToRgb('hsl(0,0,15)'),
    lightGreyBackground: hslToRgb('hsl(0,0,85)'),
    lightBackground: hslToRgb('hsl(0,0,90)'),
    primaryTextLight: hslToRgb('hsl(0,0,90)'),
    primaryTextDark: hslToRgb('hsl(0,0,10)'),
    primaryTextDarkDimmed: hslToRgb('hsl(0,0,55)'),
    primaryColor: hslToRgb('hsl(173,88,50)'),
    primaryColorDarkHover: hslToRgb('hsla(173,88,40,0.15)'),
    successBackground: hslToRgb('hsl(120,100,35)'),
    warningBackground: hslToRgb('hsl(39,100,55)'),
    errorBackground: hslToRgb('hsl(355,100,55)'),
}; */

export const bifrostColors = {
    darkBackground: rgbToHex('rgb(32,33,37)'),
    darkGreyBackground: hslToRgb('hsl(0,0,15)'),
    lightGreyBackground: hslToRgb('hsl(0,0,85)'),
    lightBackground: rgbToHex('rgb(236,237,241)'),
    primaryTextLight: hslToRgb('hsl(0,0,90)'),
    primaryTextDark: hslToRgb('hsl(0,0,10)'),
    primaryTextDarkDimmed: hslToRgb('hsl(0,0,55)'),
    primaryColor: rgbToHex('rgb(64,105,151)'),
    primaryColorDarkHover: hslToRgb('hsla(64,105,151,0.15)'),
    successBackground: hslToRgb('hsl(120,100,35)'),
    warningBackground: hslToRgb('hsl(39,100,55)'),
    errorBackground: hslToRgb('hsl(355,100,55)'),
};

const theme = createTheme({
    props: {
        MuiButton: {
            variant: 'outlined',
            disableElevation: true,
        },
    },
    palette: {
        primary: {
            main: bifrostColors.primaryColor,
            dark: bifrostColors.primaryColorDarkHover,
        },
        secondary: {
            main: bifrostColors.darkGreyBackground,
            light: bifrostColors.lightBackground,
            contrastText: bifrostColors.primaryTextDarkDimmed,
        },
        warning: {
            main: amber[400],
            dark: brown[900],
        },
        error: {
            main: red[500],
            dark: red[900],
        },
        success: {
            main: lightGreen[500],
            dark: green[900],
        },
    },
});

export default theme;
