import React, { useEffect, useState, useCallback } from 'react';
import { ConfigProps } from '@intility/flex-chat';
import { useStore } from '../state/store';
import { makeStyles, Slide, Paper } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        right: '0px',
        width: '100%',
        backgroundColor: '#F3F3F3',
        height: '100%',
    },
    chatContainer: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        width: '500px',
        backgroundColor: 'blue',
        borderLeft: '1px solid black',
        padding: '0 10px 0 10px',
        height: '90%',
    },
});

const PortalTestPage: React.FC = () => {
    const { REACT_APP_ACCOUNT_SID, REACT_APP_FLOW_SID } = process.env;
    const { state } = useStore();
    const [chatConfig, setChatConfig] = useState<ConfigProps | null>(null);
    const [chatIsMaximized, setChatIsMaximized] = useState(true);
    /*     const { toggleChatVisibility } = useChatActions();
     */ const classes = useStyles();

    /* const toggleChat = () => {
        toggleChatVisibility();
        setChatIsMaximized((prevValue) => !prevValue);
    }; */

    const toggleChat = useCallback((event) => {
        if (event.keyCode === 77) {
            //toggleChatVisibility();
            setChatIsMaximized((prevValue) => !prevValue);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', toggleChat, false);

        return () => {
            document.removeEventListener('keydown', toggleChat, false);
        };
    }, []);

    useEffect(() => {
        if (state?.userInfo?.userPrincipalName && REACT_APP_ACCOUNT_SID && REACT_APP_FLOW_SID) {
            setChatConfig({
                flexAccountSid: REACT_APP_ACCOUNT_SID,
                flexFlowSid: REACT_APP_FLOW_SID,
                user: {
                    userPrincipalName: state?.userInfo?.userPrincipalName,
                    mail: state?.userInfo?.mail,
                    mobilePhone: state?.userInfo?.mobilePhone,
                    preferredLanguage: state?.userInfo?.preferredLanguage,
                },
                theme: {
                    MainContainer: {
                        // @ts-ignore
                        position: 'relative !important',
                        top: '0px',
                        right: '0px',
                        height: 'calc(100vh - 6.5rem - 30px)',
                        maxHeight: '100vh',
                        width: '400px !important',
                    },
                    EntryPoint: {
                        display: 'none !important',
                    },
                },
            });
        }
        // eslint-disable-next-line
    }, [state?.userInfo, REACT_APP_ACCOUNT_SID, REACT_APP_FLOW_SID]);

    return (
        <section className={classes.root}>
            <Slide direction="left" in={chatIsMaximized} mountOnEnter unmountOnExit>
                <Paper className={classes.chatContainer}>
                    <p>Hello!</p>
                </Paper>
            </Slide>
        </section>
    );
};

export default PortalTestPage;
