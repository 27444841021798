/*
Author: Christian Marker (christian.marker@intility.no)
commonMuiStyles.tsx (c) 2021
Desc: File for defining common Material UI Styles
Created:  2021-05-18T11:41:06.191Z
Modified: 2021-05-18T11:43:58.515Z
*/

import { makeStyles } from "@material-ui/core/styles";

export const commonCardPageStyles = makeStyles({
    desktopRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    mobileRoot: {
        justifyContent: 'center',
    },
    cardsContainer: {},
    chatContainer: {
        width: "100%",
        margin: "0 auto"
    },
});

