import React from 'react';
import Header from './Header';
import {
    makeStyles,
    createStyles,
    rgbToHex,
    Typography,
    Paper,
} from '@material-ui/core';
import { useIsAuthenticated } from '@azure/msal-react';
import { msalInstance } from '../config/auth/config';
import { EventMessage, EventType } from '@azure/msal-browser';


const useStyles = makeStyles(() =>
    createStyles({
        rootContatiner: {
            flex: '1 0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paperRoot: {
            padding: '30px 30px 30px 30px',
            margin: '10px',
            color: '#000',
            maxWidth: '40rem',
            wordWrap: 'break-word',
            borderRadius: '7px',
        },
        loadingBox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        infoBox: {
            margin: '10px',
            padding: '50px',
            color: '#000',
            width: '30rem',
        },
        spinner: {
            marginRight: '10px',
        },
        textTitle: {
            fontSize: '2rem',
            color: rgbToHex('rgb(10,10,10)'),
            paddingBottom: '10px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        textBody: {
            color: rgbToHex('rgb(10,10,10)'),
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        actionButton: {
            fontSize: '100%',
            fontFamily: 'inherit',
            border: '1px solid #496993',
            borderRadius: '3px',
            padding: '0',
            width: '100%',
            height: '40px',
            backgroundColor: 'inherit',
            color: '#496993',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#e6e6e6',
            },
        },
    }),
);

const Authenticator: React.FC<unknown> = (props: React.PropsWithChildren<unknown>) => {
    const isAuthenticated = useIsAuthenticated();
    const { children } = props;
    const classes = useStyles();


    const resetBrowserSettings = () => {
        localStorage.clear();
        sessionStorage.clear();
        location.reload();
    }

    const handleLoginErrors = () => {
         msalInstance.addEventCallback((event: EventMessage) => {
            if ([
                EventType.LOGIN_FAILURE, 
                EventType.ACQUIRE_TOKEN_FAILURE, 
                EventType.SSO_SILENT_FAILURE, 
                EventType.LOGOUT_FAILURE,
            ].includes(event.eventType)) {
                return (
                    <React.Fragment>
                        <Header isAuthenticated={isAuthenticated} />
                        <div className={classes.rootContatiner}>
                            <Paper className={classes.paperRoot}>
                                <Typography className={classes.textTitle} variant="h2">
                                    We encountered an unexpected error when logging in.
                                </Typography>
                                <Typography className={classes.textBody}>
                                    Please try to reset your browser settings by pressing this button.
                                </Typography>
                                <br />
                                <button
                                    onClick={resetBrowserSettings}
                                    className={classes.actionButton}
                                >
                                    Reset browser settings
                                </button>
                                <br /><br />
    
                                <Typography className={classes.textBody}>
                                    If this does&apos;nt solve your problems please reach out to us on another channel (mail or
                                    phone) and provide the technician the following message:
                                </Typography>
                                <Typography className={classes.textBody}>
                                    {`${event.error?.name}: ${event.error?.message}`}
                                </Typography>
                            </Paper>
                        </div>
                    </React.Fragment>
                );
            }
        });
    }

    return (
        <React.Fragment>
            {handleLoginErrors()}
            <Header isAuthenticated={isAuthenticated} />
            {children}
        </React.Fragment>
    );
};

export default Authenticator;
