import React, { useEffect } from 'react';
import { useStore } from '../state/store';
import { createStyles, Theme, makeStyles, useMediaQuery, Button, Menu, MenuItem } from '@material-ui/core';
import chat from '../assets/images/chat.png';
import useGraph from '../hooks/useGraph';
import { backToPortalButton, departmentButton, generalDepartment, goToAiTranslateButton, leaveAiTranslateButton, procurementDepartment, signOutButton } from '../assets/text/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faExternalLinkAlt, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { handleLogin, handleLogout } from '../helpers/MsalAuthHandler';
import { msalInstance } from '../config/auth/config';


interface HeaderProps {
   isAuthenticated: boolean
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        username: {
            color: theme.palette.secondary.contrastText,
            fontWeight: 600,
        },
        image: {
            height: '25px',
            borderRadius: '4px',
        },
        header: {
            position: 'relative',
            backgroundColor: '#406997',
            color: '#fff',

            '@media only screen and (max-width: 767px)': {
                display: "none !important"
            },
        },
        headerButtons: {
            display: "flex",
            flexWrap: "wrap",
            marginBlock: "0.4em",
            marginInline: "0.5em",
            gap: "0.3em"
        },
        subHeader: {
            position: 'relative',
            backgroundColor: '#333f48',
            minHeight: '36px',
            color: '#fff',
            display: "flex",
            flexDirection: "row-reverse"
        },
        headerContent: {
            maxWidth: '87.5rem',
            margin: "0 auto",
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
        },
        subHeaderContent: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            width: '87.5rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 'inherit',
            '& *': {
                margin: '0 10px 0 10px',
            },
            '& *:last-child': {
                margin: '0 0 0 10px',
            },
        },
    }),
);

const Header: React.FC<HeaderProps> = (props) => {
    const { isAuthenticated } = props;
    const { state } = useStore();
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles();
    const { fetchUserFromGraph, fetchUserPictureFromGraph } = useGraph();

    const [departmentAnchorEl, setDepartmentAnchorEl ] = React.useState(null);

    const norwegianLanguage = state?.userInfo?.preferredLanguage?.includes('-NO');

    const handleDepartmentDropDownClick = (event) => {
        setDepartmentAnchorEl(event.currentTarget);
    };
    
    const handleDepartmentDropDownClose = () => {
        setDepartmentAnchorEl(null);
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchUserFromGraph();
            fetchUserPictureFromGraph();
        }
        // eslint-disable-next-line
    }, []);

    const renderUserInfo = () => {
        if (state?.userInfo) {
            return <p style={{ fontSize: '12.8px' }}>{state?.userInfo?.displayName}</p>;
        }

        if (state?.fetchingUserInfo) {
            return <p style={{ fontSize: '12.8px' }}>Loading...</p>;
        }

        return <p style={{ fontSize: '12.8px' }}>Could not retrieve user information</p>;
    };

    const getSubHeaderContent = () => {
            // Return in reverted order because of right allignment in CSS flexDirection: 'row-reverse'
            return props.isAuthenticated ? (
                <>
                    <button className="header__link" onClick={() => handleLogout(msalInstance)}>
                        {norwegianLanguage ? signOutButton.no : signOutButton.en}
                    </button>
                    <p>|</p>

                    {renderUserInfo()}

                    {state?.userPicture && (
                        <img
                            alt="profile"
                            className={classes.image}
                            src={URL.createObjectURL(state.userPicture)}
                        />
                    )}
                </>
            ) : (
                    <button className="header__link" onClick={() => handleLogin(msalInstance)}>
                        Sign in
                    </button>
                );
    };

    return (
        <>
        </>
    );
};

export default Header;
