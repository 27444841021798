import { Configuration, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";

// Shameless kopi av Christopher sin kode :)

export const msalConfig: Configuration = {
    auth: {
        clientId: 'e1e41320-cba1-41fa-a76c-15e480e0398a',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
}


export const msalInstance = new PublicClientApplication(msalConfig);


export const graphScope = {
    scopes: ['https://graph.microsoft.com/User.Read'],
}

export const requestAccessToken = async (scope: {scopes: string[]}): Promise<string> => {
    await msalInstance.handleRedirectPromise();
    const accounts = msalInstance.getAllAccounts();

    const request = {
        ...scope,
        account: accounts[0]
    };

    return new Promise((resolve, _reject) => {
        msalInstance
        .acquireTokenSilent(request)
        .then(({accessToken}) => {
            return resolve(accessToken);
        })
        .catch((err) => {
            if(err instanceof InteractionRequiredAuthError){
               return msalInstance.acquireTokenRedirect({
                    ...request
                });
            } else {
                console.error('acquireTokenRedirect: ', err.errorCode);
            }
        })
    });
}