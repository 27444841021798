import { requestAccessToken, graphScope } from '../config/auth/config';

const authorizedFetch = async (url: RequestInfo, options: RequestInit = { headers: {} }) => {
    const token = await requestAccessToken(graphScope);
    
    if (!token) {
        return null;
    }

    return fetch(url, {
        ...options,
        headers: {
            Authorization: `Bearer ${token}`,
            ...options.headers,
        },
    }).then((result) => {
        return result;
    }).catch((error) => {
        console.error("Error:", error.message);
    });
};

export default authorizedFetch;
