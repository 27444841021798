import React from 'react';
import {
    CircularProgress,
    makeStyles,
    createStyles,
    rgbToHex,
    Typography,
    Paper,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        rootContatiner: {
            flex: '1 0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paperRoot: {
            padding: '30px 30px 30px 30px',
            margin: '10px',
            color: '#000',
            maxWidth: '40rem',
            wordWrap: 'break-word',
            borderRadius: '7px',
        },
        loadingBox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        infoBox: {
            margin: '10px',
            padding: '50px',
            color: '#000',
            width: '30rem',
        },
        spinner: {
            marginRight: '10px',
        },
        textTitle: {
            fontSize: '2rem',
            color: rgbToHex('rgb(10,10,10)'),
            paddingBottom: '10px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        textBody: {
            color: rgbToHex('rgb(10,10,10)'),
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        actionButton: {
            fontSize: '100%',
            fontFamily: 'inherit',
            border: '1px solid #496993',
            borderRadius: '3px',
            padding: '0',
            width: '100%',
            height: '40px',
            backgroundColor: 'inherit',
            color: '#496993',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#e6e6e6',
            },
        },
    }),
);


export const LoadingComponent = () => {
    const classes = useStyles();
    
    return (
        <div className={classes.rootContatiner}>
            <Paper className={`${classes.paperRoot} ${classes.loadingBox}`}>
                <CircularProgress className={classes.spinner} />
                <Typography className={classes.textTitle} variant="h2">
                    Loading...
                </Typography>
            </Paper>
        </div>
    )
}