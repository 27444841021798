import authorizedFetch from '../provider/authorizedFetch';
import { useStore } from '../state/store';
import { GraphUser } from '../interfaces/dtos/msGraph';

const useGraph = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { state, dispatch } = useStore();

    const fetchUserPictureFromGraph = async () => {
        dispatch({ type: 'fetchingPicture', fetchingUserPicture: true });

        const result = await authorizedFetch(
            'https://graph.microsoft.com/v1.0/me/photos/120x120/$value',
            {
                headers: { 'Content-Type': 'image/jpg' },
            },
        );

        if (result && result.ok) {
            const parsedResult = await result.blob();

            dispatch({ type: 'setPicture', userPicture: parsedResult });
        }
    };

    const fetchUserFromGraph = async () => {
        dispatch({ type: 'fetchingInfo', fetchingUserInfo: true });

        const result = await authorizedFetch('https://graph.microsoft.com/v1.0/me/');

        if (result && result.ok) {
            const parsedResult: GraphUser = await result.json();

            dispatch({ type: 'setInfo', userInfo: parsedResult });
        }
    };

    return { fetchUserPictureFromGraph, fetchUserFromGraph };
};

export default useGraph;
