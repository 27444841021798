/*
Author: Christian Marker (christian.marker@intility.no)
WebShopPage.tsx (c) 2021
Desc: Sub page for chats 
Created:  2021-03-17T11:31:01.416Z
Modified: 2021-05-20T13:32:03.428Z
*/

import React, { useEffect } from 'react';
import { FlexChat, ConfigProps } from '@intility/flex-chat';
import { useMediaQuery } from '@material-ui/core';
import { commonCardPageStyles } from '../styles/commonMuiStyles';

interface PageProps {
    chatConfig?: ConfigProps;
    isDev: boolean;
}

const WebShopPage: React.FunctionComponent<PageProps> = ({ chatConfig, isDev }) => {
    const classes = commonCardPageStyles();
    const isDesktop = useMediaQuery('(min-width:767px)');

    useEffect(() => {
        if (chatConfig) {
            chatConfig.preEngagementFormMessage = {
                no: "Din samtale vil bli sendt til innkjøpsavdelingen, hvor en agent vil hjelpe deg med ditt spørsmål.", 
                en: "Your conversation will be sent to the procurement department, where an agent will help you regarding your query."
            }

            chatConfig.theme = {
                MainContainer: {
                    maxHeight: "100vh",
                    height: "100vh",
                    position: "static !important",
                },
                CloseButton: {
                    display: 'none !important',
                },
                EntryPoint: {
                    display: "none !important"
                },
            };
        }
    }, [chatConfig]);

    return (
        <section>
            <div
                className={
                    isDesktop ? classes.desktopRoot : `${classes.desktopRoot} ${classes.mobileRoot}`
                }
            >
                <div className={classes.chatContainer}>
                    {chatConfig && (
                        <FlexChat config={chatConfig} isDarkMode={false} isDev={isDev}/>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WebShopPage;
