import React from 'react';
import {
    Paper,
    Typography,
    rgbToHex,
    Card,
    CardMedia,
    CardContent,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import phoneIcon from '../assets/images/phone-solid.svg';
import mailIcon from '../assets/images/envelope-regular.svg';

const useStyles = makeStyles(() =>
    createStyles({
        paperRoot: {
            padding: '20px 30px 30px 30px',
            margin: '10px',
            color: '#000',
            maxWidth: '35rem',
            wordWrap: 'break-word',
            borderRadius: '7px',
        },
        textTitle: {
            fontSize: '2rem',
            color: rgbToHex('rgb(10,10,10)'),
            paddingBottom: '10px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        textBody: {
            color: rgbToHex('rgb(10,10,10)'),
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },

        cardRoot: {
            display: 'inline-flex',
            backgroundColor: '#406997',
            boxShadow: 'none',
            height: '40px',
            borderRadius: '7px',
        },
        cardContent: {
            flex: '1 0 auto',
            wordBreak: 'break-word',
            padding: '8px 10px 0 0',
            textDecoration: 'underline #FFF',
            color: '#FFF',
            '& h6': {
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
            },
        },
        cardImage: {
            width: '35px',
        },
        cardLink: {
            marginRight: '15px',
        },
    }),
);

const Link = (props: { url: string; text: string }) => (
    <span>
        <a style={{ color: '#406997' }} rel="noopener noreferrer" target="_blank" href={props.url}>
            {props.text}
        </a>
    </span>
);

const NotSupported: React.FC = () => {
    const classes = useStyles();

    return (
        <div
            style={{
                flex: '1 0 auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Paper className={classes.paperRoot}>
                <Typography className={classes.textTitle}>Unsupported Browser</Typography>
                <Typography className={classes.textBody}>
                    {`Intility Chat isn't available in Internet Explorer.`}
                    <br />
                    We only support the recent versions of{' '}
                    <Link url="https://www.google.com/chrome/" text="Chrome" />
                    , <Link url="https://www.mozilla.org/firefox/new/" text="Firefox" />, and{' '}
                    <Link url="https://www.microsoft.com/edge/" text="Edge" />.
                    <br />
                    <br />
                    Feel free to contact our support team on phone or mail
                </Typography>
                <br />
                <div>
                    <a href={'tel:004724103306'} className={classes.cardLink}>
                        <Card className={classes.cardRoot}>
                            <CardMedia className={classes.cardImage} image={phoneIcon} />
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h6">+47 24 10 33 06</Typography>
                            </CardContent>
                        </Card>
                    </a>
                    <a href={'mailto:support@intility.no'} className={classes.cardLink}>
                        <Card className={classes.cardRoot}>
                            <CardMedia className={classes.cardImage} image={mailIcon} />
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h6">support@intility.no</Typography>
                            </CardContent>
                        </Card>
                    </a>
                </div>
            </Paper>
        </div>
    );
};

export default NotSupported;
