import { ConfigProps, FlexChat } from "@intility/flex-chat";
import { useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStore } from "../state/store";
import { commonCardPageStyles } from "../styles/commonMuiStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { LoadingComponent } from "../components/Loading";

interface PageProps {
    chatConfig?: ConfigProps;
    isDev: boolean;
    problemDescription?: string | null;
}

const TranslationPage: React.FC<PageProps> = ({ chatConfig, isDev, problemDescription }) => {
    const { state } = useStore();
    const classes = commonCardPageStyles();
    const isDesktop = useMediaQuery("(min-width:767px)");

    useEffect(() => {
        if (chatConfig) {
            if (isDev) {
                chatConfig.loglevel = "debug";
                chatConfig.preEngagementFormMessage = {
                    no: "Din samtale vil bli sendt til testkøen.",
                    en: "Your conversation will be sent to the testing queue.",
                };
            }

            chatConfig.theme = {
                MainContainer: {
                    maxHeight: "100vh",
                    height: "100vh",
                    position: "static !important",
                },
                CloseButton: {
                    display: "none !important",
                },
                EntryPoint: {
                    display: "none !important",
                },
            };
        }
    }, [chatConfig, isDev]);

    return (
        <>
            {!state?.userInfo ? (
                <LoadingComponent />
            ) : (
                <section>
                    {state?.userInfo?.preferredLanguage === undefined && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                            <div
                                style={{
                                    width: "86.5rem",
                                    backgroundColor: "#dd5061",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "3px",
                                }}
                            >
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                &nbsp;&nbsp;
                                <p style={{ margin: "0" }}>
                                    It looks like your user is missing a preferred language, contact your system admin or visit{" "}
                                    <a href='https://portal.intility.no/no/user/self/'>Intility Portal</a> to fix this.
                                    <br />
                                    For now your language will default to English
                                </p>
                            </div>
                        </div>
                    )}

                    <div className={isDesktop ? classes.desktopRoot : `${classes.desktopRoot} ${classes.mobileRoot}`}>
                        <div className={classes.chatContainer}>
                            {chatConfig && (
                                <FlexChat
                                    enableTranslation={true}
                                    config={chatConfig}
                                    isDarkMode={false}
                                    isDev={isDev}
                                    problemDescription={problemDescription}
                                />
                            )}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default TranslationPage;
