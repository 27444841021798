import React, { useEffect } from "react";
import { FlexChat, ConfigProps } from "@intility/flex-chat";
import { commonCardPageStyles } from "../styles/commonMuiStyles";
import { useMediaQuery } from "@material-ui/core";
import "../styles/tempMessageStyles.css";

interface PageProps {
    chatConfig?: ConfigProps;
    isDev: boolean;
    problemDescription?: string | null;
}

const CompanyPage: React.FunctionComponent<PageProps> = ({ chatConfig, isDev, problemDescription }) => {
    const classes = commonCardPageStyles();
    const isDesktop = useMediaQuery("(min-width:767px)");

    useEffect(() => {
        if (chatConfig) {
            if (isDev) {
                chatConfig.loglevel = "debug";
                chatConfig.preEngagementFormMessage = {
                    no: "Din samtale vil bli sendt til testkøen.",
                    en: "Your conversation will be sent to the testing queue.",
                };
            }

            chatConfig.theme = {
                MainContainer: {
                    maxHeight: "100vh",
                    height: "100vh",
                    position: "static !important",
                },
                CloseButton: {
                    display: "none !important",
                },
                EntryPoint: {
                    display: "none !important",
                },
            };
        }
    }, [chatConfig, isDev]);

    return (
        <section>
            <div className={isDesktop ? classes.desktopRoot : `${classes.desktopRoot} ${classes.mobileRoot}`}>
                <div className={classes.chatContainer}>
                    {chatConfig && (
                        <FlexChat config={chatConfig} isDarkMode={false} isDev={isDev} problemDescription={problemDescription} />
                    )}
                </div>
            </div>
        </section>
    );
};

export default CompanyPage;
