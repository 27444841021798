import React, { createContext, useContext, useReducer } from 'react';
import { GraphUser } from '../interfaces/dtos/msGraph';

interface StoreContext {
    dispatch: any;
    state: StoreState;
}

interface StoreState {
    userInfo?: GraphUser;
    fetchingUserInfo: boolean;
    userPicture?: Blob;
    fetchingUserPicture: boolean;
}

interface Action {
    type: string;
    userInfo?: GraphUser;
    fetchingUserInfo?: boolean;
    userPicture?: Blob;
    fetchingUserPicture?: boolean;
}

const initialState: StoreState = {
    userInfo: undefined,
    fetchingUserInfo: false,
    userPicture: undefined,
    fetchingUserPicture: false,
};

const StoreContext = createContext<Partial<StoreContext>>({});

const reducer = (state: StoreState, action: Action): StoreState => {
    switch (action.type) {
        case 'setInfo':
            return {
                ...state,
                userInfo: action.userInfo,
                fetchingUserInfo: false,
            };
        case 'fetchingInfo':
            return {
                ...state,
                fetchingUserInfo: action.fetchingUserInfo as boolean,
            };
        case 'setPicture':
            return {
                ...state,
                userPicture: action.userPicture,
                fetchingUserPicture: false,
            };
        case 'fetchingPicture':
            return {
                ...state,
                fetchingUserPicture: action.fetchingUserPicture as boolean,
            };
        case 'reset':
            return {
                ...state,
                userInfo: undefined,
                fetchingUserInfo: false,
                userPicture: undefined,
                fetchingUserPicture: false,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const StoreProvider: React.FC<any> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext);
// export default StoreProvider;
